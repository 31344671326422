#navbar-dark-example ul {
    gap: 16px;
    /* background-color: white; */
} 
   
      .navd  :hover{
        color: white;
        
    }

     .NavDropdown.Item:hover{
            
    }

    NavDropdown .NavDropdown.Item link :hover{
        color: #eee;
        
         
        
      }


      .dropdown-menu .show{
       position:initial;
         left:65%;
    
      
       top:auto;
      
     
       
        
       }

  .navd dropdown-item a{
    left:100%;
    width:140px;
    top:0;
  }


  .navd .Link{
    left:50%;
  }

  #nav-bar{
color: white;
/* background-color: white; */
  }

   /* #menu.header-nav-inner{
    color: white;
  }
  .header-nav .is-active{
    background-color: white;
  }  */