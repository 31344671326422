* {
  box-sizing: border-box;
}

/* Add styles to the form container */
#myForm {
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.form-container {
  text-align: left;
  max-width: 380px;
  background-color: white;
  border: 2px solid #f1f1f1;
}

/* Full-width input fields */
.form-container input[type=text],
.form-container input[type=password],
.form-container input[type=tel],
.form-container input[type=email],
.form-container textarea[type=textarea] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus,
.form-container input[type=password]:focus,
.form-container input[type=tel]:focus,
.form-container input[type=email]:focus,
.form-container textarea[type=textarea]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #111211;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}
