
.fa fa-search{
    height:20px;
    width:20px;
}


form.example input[type=text] {
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background: #f1f1f1;
  }
  
  
  form.example button {

    margin-left: -45px;
    width: 30px;
    padding: 10px;
    background: black;
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none; /* Prevent double borders */
    cursor: pointer;
  }

  .accordion{
    
    margin: 0px;
padding-left: 180px;
  }


  .Accordion{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color:ghostwhite;
    
  }

  .pagination {
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  
  .page-item .page-link {
    position: relative;
    display: block;
    margin: 0 4px;
    min-height: 15px;
   
   
    text-align: center;
    line-height: 20px;
    color: black;
    text-decoration: none;
    letter-spacing: 0.01071em;
    border-radius: 16px;
   
    font-size: 13px;
    min-width: 32px;
  }
  
  .page-item a.page-link:hover {
    background-color: #cccccc;
  }
  
  .page-item.active .page-link {
   
    color: #ffffff;
    background-color: black;
  }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }