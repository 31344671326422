
div.card {
  overflow: hidden;
}

.card {
  width: 100%;
  
  margin-bottom: 0px;
  background: #ffffff;
  box-shadow: 1px 1px 1px #dfdfdf;
  box-sizing: border-box;
  height: 100% !important;
}