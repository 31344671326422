// .testimonial {

// 	.section-inner {
// 		padding-top: $testimonial--padding-t__mobile;
// 		padding-bottom: $testimonial--padding-b__mobile;
// 	}

// 	.section-header {
// 		padding-bottom: $testimonial-header--padding__mobile;
// 	}

// 	.tiles-wrap {
// 		@if ( $testimonial-items--padding__mobile != null ) {
// 			margin-right: -($testimonial-items--padding__mobile * 0.5);
// 			margin-left: -($testimonial-items--padding__mobile * 0.5);
// 			margin-top: -($testimonial-items--padding__mobile * 0.5);

// 			&:last-of-type {
// 				margin-bottom: -($testimonial-items--padding__mobile * 0.5);
// 			}

// 			&:not(:last-of-type) {
// 				margin-bottom: ($testimonial-items--padding__mobile * 0.5);
// 			}
// 		}

// 		&.push-left {

// 			&::after {
// 				flex-basis: $testimonial-item--width;
// 				max-width: $testimonial-item--width;
// 				@if ( $testimonial-items--padding__mobile != null ) {
// 					padding-left: $testimonial-items--padding__mobile * 0.5;
// 					padding-right: $testimonial-items--padding__mobile * 0.5;
// 				}
// 			}
// 		}
// 	}

// 	.tiles-item {
// 		flex-basis: $testimonial-item--width;
// 		max-width: $testimonial-item--width;
// 		@if ( $testimonial-items--padding__mobile != null ) {
// 			padding: $testimonial-items--padding__mobile * 0.5;
// 		}
// 	}

// 	.tiles-item-inner {
// 		padding-top: $testimonial-item--inner-padding-v;
// 		padding-bottom: $testimonial-item--inner-padding-v;
// 		padding-left: $testimonial-item--inner-padding-h;
// 		padding-right: $testimonial-item--inner-padding-h;
// 	}
// }

// .testimonial-item-content {
// 	flex-grow: 1;
// }
// .testmon{
// 	color: white;
// }

// .testimonial-item-source {
// 	display: inline-flex; // to allow centering when using .content-center on a parent tag
// 	flex-wrap: wrap;
// 	align-items: center;
// }

// @include media( '>medium' ) {

// 	.testimonial {

// 		.section-inner {
// 			padding-top: $testimonial--padding-t__desktop;
// 			padding-bottom: $testimonial--padding-b__desktop;
// 		}

// 		.section-header {
// 			padding-bottom: $testimonial-header--padding__desktop;
// 		}

// 		@if ( $testimonial-items--padding__desktop != null ) {

// 			.tiles-wrap {
// 				margin-right: -($testimonial-items--padding__desktop * 0.5);
// 				margin-left: -($testimonial-items--padding__desktop * 0.5);
// 				margin-top: -($testimonial-items--padding__desktop * 0.5);

// 				&:last-of-type {
// 					margin-bottom: -($testimonial-items--padding__desktop * 0.5);
// 				}

// 				&:not(:last-of-type) {
// 					margin-bottom: ($testimonial-items--padding__desktop * 0.5);
// 				}

// 				&.push-left {

// 					&::after {
// 						padding-left: $testimonial-items--padding__desktop * 0.5;
// 						padding-right: $testimonial-items--padding__desktop * 0.5;
// 					}
// 				}
// 			}

// 			.tiles-item {
// 				padding: $testimonial-items--padding__desktop * 0.5;
// 			}
// 		}
// 	}
// }

#testimonial4 {
	overflow: hidden;
	min-height: 375px;
	position: relative;
	background: #eceef0;
	margin-bottom:10%;
  }
  
  #testimonial4 .carousel-inner {
	width: 75%;
	margin: auto;
  }
  
  #testimonial4 .carousel-inner:hover {
	cursor: -moz-grab;
	cursor: -webkit-grab;
  }
  
  #testimonial4 .carousel-inner:active {
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
  }
  
  #testimonial4 .carousel-inner .item {
	overflow: hidden;
  }
  
  .testimonial4_indicators .carousel-indicators {
	left: 0;
	margin: 0;
	width: 100%;
	font-size: 0;
	height: 20px;
	bottom: 15px;
	padding: 0 5px;
	cursor: e-resize;
	overflow-x: auto;
	overflow-y: hidden;
	position: absolute;
	text-align: center;
	white-space: nowrap;
  }
  
  .testimonial4_indicators .carousel-indicators li {
	padding: 0;
	width: 10px;
	height: 10px;
	border: none;
	text-indent: 0;
	margin: 2px 3px;
	cursor: pointer;
	display: inline-block;
	background: #ffffff;
	-webkit-border-radius: 100%;
	border-radius: 100%;
  }
  
  .testimonial4_indicators .carousel-indicators .active {
	padding: 0;
	width: 10px;
	height: 10px;
	border: none;
	margin: 2px 3px;
	background-color: #000;
	-webkit-border-radius: 100%;
	border-radius: 100%;
  }
  
  .testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
	height: 3px;
  }
  
  .testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
	background: #eeeeee;
	-webkit-border-radius: 0;
	border-radius: 0;
  }
  
  .testimonial4_control_button .carousel-control {
	top: 175px;
	opacity: 1;
	width: 40px;
	bottom: auto;
	height: 40px;
	font-size: 10px;
	cursor: pointer;
	font-weight: 700;
	overflow: hidden;
	line-height: 38px;
	text-shadow: none;
	text-align: center;
	position: absolute;
	background: transparent;
	border: 2px solid #ffffff;
	text-transform: uppercase;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
	transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  }
  
  .testimonial4_control_button .carousel-control.left {
	left: 7%;
	right: auto;

  }
  
  .testimonial4_control_button .carousel-control.right {
	right: 7%;
	left: auto;
	
  }
  
  .testimonial4_control_button .carousel-control.left:hover,
  .testimonial4_control_button .carousel-control.right:hover {
	color: #000;
	background: #fff;
	border: 2px solid #fff;
  }
  .testimonial .left carousel-control-icon,
.testimonial .right carousel-control-icon {
  width: 35px;
  height: 35px;
  color: #030000;
}


  
  .testimonial4_header {
	top: 0;
	left: 0;
	bottom: 0;
	width: 550px;
	display: block;
	margin: 30px auto;
	text-align: center;
	position: relative;
  }
  
  .testimonial4_header h4 {
	color: blue;
	font-size: 30px;
	font-weight: 600;
	position: relative;
	letter-spacing: 1px;
	text-transform: uppercase;
  }
  
  .testimonial4_slide {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 70%;
	margin: auto;
	padding: 20px;
	position: relative;
	text-align: center;
  }
  
  .testimonial4_slide img {
	top: 0;
	left: 0;
	right: 0;
	width:200px;
	height: 100px;
	margin: auto;
	display: block;
	color: #f2f2f2;
	font-size: 18px;
	line-height: 46px;
	text-align: center;
	position: relative;
  }
  
  .testimonial4_slide p {
	color: #030000;
	font-size: 16px;
	margin: 40px 0 20px 0;
  }
  
  .testimonial4_slide h4 {
	color:blue;
	font-size: 24px;
	font-weight: bold;
  }

  .gif-carousal .carousel-indicators {
	bottom: -66px ;
	background-color: rgb(126, 126, 125);
  }
  
  @media only screen and (max-width: 480px) {
	.testimonial4_control_button .carousel-control {
	  display: none;
	}
	.testimonial4_header {
	  width: 95%;
	}
	.testimonial4_header h4 {
	  font-size: 20px;
	}
	.testimonial4_slide {
	  width: 98%;
	  padding: 5px;
	}
  }
  
  @media (min-width: 481px) and (max-width: 767px) {
	.testimonial4_control_button .carousel-control.left {
	  left: 2%;
	}
	.testimonial4_control_button .carousel-control.right {
	  right: 2%;
	}
	.testimonial4_header {
	  width: 95%;
	}
	.testimonial4_slide {
	  width: 98%;
	  padding: 5px;
	}
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
	.testimonial4_control_button .carousel-control.left {
	  left: 5%;
	}
	.testimonial4_control_button .carousel-control.right {
	  right: 5%;
	}
  }
  
  @-webkit-keyframes psBoxShadowEffect_2 {
	0% {
	  opacity: 0.3;
	}
	40% {
	  opacity: 0.5;
	  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff, 0 0 0 10px rgba(255, 255, 255, 0.5);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 5px 5px #ffffff, 0 0 0 5px rgba(255, 255, 255, 0.5);
	  -webkit-transform: scale(1.5);
	  opacity: 0;
	}
  }
  
  @keyframes psBoxShadowEffect_2 {
	0% {
	  opacity: 0.3;
	}
	40% {
	  opacity: 0.5;
	  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff, 0 0 0 10px rgba(255, 255, 255, 0.5);
	}
	100% {
	  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 5px 5px #ffffff, 0 0 0 5px rgba(255, 255, 255, 0.5);
	  transform: scale(1.5);
	  opacity: 0;
	}
  }