
.title-pages{
margin-top: 100px;
text-align: center;
font-family: Arial, Helvetica, sans-serif;

}

.title-pages h3{
  color:black;
}

.title-pages{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
}

.title-pages input{
   
   
    width: 160px;
    height: 30px;
    font-size: 18px;
    padding-left:"82px" 
}

.pagination{
    margin-left: 125px;

}
.title-pages p{
    margin:10px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 10px;
}

.blog1{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
}

.blog2{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog3{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog4{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog5{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog7{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog6{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog8{
  padding:150px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}

.blog9{
    padding:150px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.blog10{
  padding:150px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}
.blog{
  padding:150px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}

/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  
}

td, th {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
  width: 30%;
} */
.headerr{
   color: white;
   text-decoration-color: white;
}
.googlesearch {
    font-family: sans-serif;
    text-align: center;
  }
  
  .leftBox {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 12px;
  }
  
  .rightBox {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 12px;
  }
  
  .all {
    justify-content: space-between;
    display: flex;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    background: rgb(238, 174, 202);
    background: radial-gradient(
      circle,
      rgba(238, 174, 202, 1) 0%,
      rgba(199, 233, 148, 1) 100%
    );
  }
  
  h1 {
    text-align: center;
    margin: 2rem 0 4rem 0;
  }
  
  .accordion {
    max-width: 1300px;
    margin: 2rem auto;
  }
  
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color:ghostwhite;
    
    
  }
  
  .accordion-title:hover {
    background-color:ghostwhite;
   
    
    
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  }
  
  .accordion-content {
    background-color:white;
  }
  
  @media screen and (max-width: 700px) {
    body {
      font-size: 18px;
    }
  
    .accordion {
      width: 90%;
    }
  }

 

  .Star{
    padding:100px ;
    font-family: Arial, Helvetica, sans-serif;
    overflow: auto;
}
.Timeless{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}
.Orbital{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}
.AutomaticProgramming{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto; 
}
.AssistedAutoProgramming{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto; 
}
.VisualLowcodeProgramming{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;  
}
.VisualNocodeProgramming{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;  
}
.ConfigurationProgramming{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto; 
}
.CICDProgramming{
  padding:100px ;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto; 
}
.headerr{
  color: white;
  font-style: normal;
  text-decoration-color: white;

}
.blog_frame{
  display: flex;
  
}

button {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  border: none;
  
}

button:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #f1f1f1;
  color: black;
}

#nav-dropdown-dark-example{
  color:black;
  background-color: white;

}

#nav-bar{
  color:black;
  background-color: white;
}
#navbar-dark-example{
  color:black;
  background-color: white;

}

#menu .header-nav-inner{
  background-color: white;
}








.Blogsearchh {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-container {
  width: 280px;
  display: flex;
  flex-direction: column;
}

.dropdownblog {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
}

.dropdownblog:empty {
  border: none;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
}

input {
  width: 220px;
}

.search-inner {
  display: flex;
}


/*
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination a {
  width: 40px;
  height: 40px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: white;
  border-color:whitesmoke;
}

.pagination button.active {
  font-weight: 900;
  border-color: #101010;
  background: #ffe400;
  color: #101010;
}


*/
