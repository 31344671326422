 /*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-wrap {
  border-top: 0px solid #ececec;
  border-left: 0px solid #ececec;
  }
  
  .clients .client-logo {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0px solid #ececec;
    border-bottom: 0px solid #ececec;
    overflow: hidden;
    background: #fff;
    height: 125px;
  }
  
  .clients .client-logo img {
    transition: all 0.3s ease-in-out;
    height: 110px;
   
  }
  
  .clients .client-logo:hover img {
    filter: none;
    transform: scale(1.1);
  }
  
  @media(max-width: 425px) {
    .col {
      flex-basis: auto;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }
}
@media(min-width: 426px) and  (max-width: 961px){
  .col {
    flex-basis: auto;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 50%;
  }
}




  /*--------------------------------------------------------------
# Certifications
--------------------------------------------------------------*/
.certifications .clients-wrap {
  border-top: 0px solid #ececec;
  border-left: 0px solid #ececec;
}

.certifications .ct-logo {
  padding: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0px solid #ececec;
  border-bottom: 0px solid #ececec;
  overflow: hidden;
  background: #fff;
  height: 150px;
}

.certifications .ct-logo img {
  transition: all 0.3s ease-in-out;
  height: 120px;
 
}

.certifications .ct-logo:hover img {
  filter: none;
  transform: scale(1.1);
}

.owl-carousel {
  position: relative;
}
.owl-next, .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.owl-next {
  right: 0;
  display: flex;
}
.owl-prev{
  left: 0;
  display: flex;
}

/* .owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.owl-nav button.owl-prev {
  left: 0;
}
.owl-nav button.owl-next {
  right: 0;
}


.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
}
span {
    font-size: 70px;    
    position: relative;
    top: -5px;
}
.owl-nav button:focus {
    outline: none;
} */



 /* .owl-nav  {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
} */
/* .owl-nav .owl-prev {
  left: 0;
}
.owl-nav .owl-next {
  right: 0;
}




.owl-nav  {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
}
span {
    font-size: 70px;    
    position: relative;
    top: -5px;
}
.owl-nav :focus {
    outline: none;
}  */